import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LogoImg from './sm-logo.jpg';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../CopyRight';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { useSession, loginWithEmail } from '../Session';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '../../constants/Routes';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#515CE6',
		'&:hover, &:focus': {
			backgroundColor: '#515CE6'
		}
	},
	cover: {
		width: 90,
		height: 90,
		marginBottom: theme.spacing(3)
	},
	title: {
		fontFamily: 'Fredericka the Great',
		fontSize: '2.5em'
	}
}));

const SignIn = () => {
	const classes = useStyles();
	const user = useSession();
	const [ error, setError ] = useState('');

	const handleLogin = async (e) => {
		e.preventDefault();
		const { email, password } = e.target.elements;
		try {
			await loginWithEmail(email.value, password.value);
		} catch (err) {
			setError(err.message || 'Please try again.');
		}
	};

	if (user) {
		return <Redirect to={ROUTES.LANDING} />;
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.paper}>
					<CardMedia className={classes.cover} image={LogoImg} title="Sistemas medioambientals" />
					<Typography component="h1" variant="h5" className={classes.title}>
						Inici de sessió
					</Typography>
					<form className={classes.form} onSubmit={handleLogin} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Correu electrònic"
							type="email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Contrasenya"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Inicia sessió
						</Button>
					</form>
				</div>
			</div>
			<Box mt={5}>
				{error && <Alert severity="error">{error}</Alert>}
				<br />
				<Copyright />
			</Box>
		</Container>
	);
};

export default SignIn;
