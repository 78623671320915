import app from "../Firebase";
import "firebase/auth";
import { useContext } from "react";
import { userContext } from "../../context/UserContext";

export const useSession = () => {
  const { user } = useContext(userContext);
  return user;
};

export const loginWithEmail = async (email, password) => {
  try {
    const results = await app
      .auth()
      .signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const createUserWithEmail = async (email, password) => {
  try {
    await app.auth().createUserWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signOut = () => app.auth().signOut();