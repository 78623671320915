import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TextField from '@material-ui/core/TextField';
import app from '../Firebase';
import Spinner from '../Spinner';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSession } from '../Session';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(
	FilePondPluginImageCrop,
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginImageTransform,
	FilePondPluginImageResize
);

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(4)
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	actionsContainer: {
		marginBottom: theme.spacing(2)
	},
	resetContainer: {
		padding: theme.spacing(3)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	card: {
		display: 'flex',
		maxHeight: 120,
		marginBottom: 10
	},
	details: {
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		flex: '1 0 auto'
	},
	cover: {
		width: 120,
		height: 120
	},
	playIcon: {
		height: 38,
		width: 38
	},
	menuButton: {
		marginRight: theme.spacing(1)
	},
	titleInforme: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		maxWidth: '80%',
		margin: 'auto'
	},
	cardInspector: {
		marginBottom: theme.spacing(2),
		maxWidth: '90%',
		marginRight: 'auto',
		marginLeft: 'auto'
	},
	cardStepper: {
		maxWidth: '95%',
		marginRight: 'auto',
		marginLeft: 'auto'
	},
	swithPorta: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
}));

const theme = createMuiTheme({
	overrides: {
		MuiStepIcon: {
			root: {
				'&$active': {
					color: '#BECD00'
				},
				'&$completed': {
					color: '#BECD00'
				}
			},
			text: {
				fill: '#ffffff'
			}
		}
	}
});

function useProjects(distritoUID = 'distrito1') {
	const [ projects, setProjects ] = React.useState([]);
	const [ distritos, setDistritos ] = React.useState([]);
	const [ parques, setParques ] = React.useState([]);
	const [ loading, setLoading ] = React.useState(false);
	const [ error, setError ] = React.useState('');

	React.useEffect(
		() => {
			const fetchData = () => {
				setLoading(true);
				const projects = app.firestore().collection('Proyectos').onSnapshot((querySnapshot) => {
					const newProject = querySnapshot.docs.map((doc) => ({
						uid: doc.id,
						...doc.data()
					}));
					setProjects(newProject);
				});
				const distritos = app
					.firestore()
					.collection('Proyectos')
					.doc('espais-verds')
					.collection('Distritos')
					.orderBy('Nombre')
					.onSnapshot((querySnapshot) => {
						const newDistrito = querySnapshot.docs.map((doc) => ({
							uid: doc.id,
							...doc.data()
						}));
						setDistritos(newDistrito);
					});

				const parques = app
					.firestore()
					.collection('Proyectos')
					.doc('espais-verds')
					.collection('Distritos')
					.doc(distritoUID)
					.collection('Parques')
					.orderBy('Nombre')
					.onSnapshot((querySnapshot) => {
						const newParque = querySnapshot.docs.map((doc) => ({
							uid: doc.id,
							...doc.data()
						}));
						setParques(newParque);
					});

				setLoading(false);
			};
			fetchData();
		},
		[ distritoUID ]
	);

	return { projects, distritos, parques, loading, error };
}

function UploadPorta() {
	const classes = useStyles();
	/* Variables upload */
	const [ parquesUId, setParquesUId ] = React.useState('parc1');
	const [ distritoUId, setDistritoUId ] = React.useState('distrito1');
	const [ files, setFiles ] = React.useState([]);
	const [ titulo, setTitulo ] = React.useState('');
	const [ ubi, setUbi ] = React.useState('');
	const [ loadingUpload, setLoadingUpload ] = React.useState(false);

	/* Variables funcionamiento */
	const [ activeStep, setActiveStep ] = React.useState(0);
	const [ date, setDate ] = React.useState(new Date().toLocaleString());
	const steps = 5;
	const inputLabel = React.useRef(null);
	const [ labelWidth, setLabelWidth ] = React.useState(0);

	/* Preload data */
	const { projects, distritos, parques, loading, error } = useProjects(distritoUId);
	const user = useSession();
	const found = projects.map((project) => project.Nombre);

	/* Metodos para funcionamiento */
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleResetText = () => {
		setParquesUId('parc1');
		setDistritoUId('distrito1');
		setFiles([]);
		setTitulo('');
		setUbi('');
	};

	/* Metodos para upload */
	const uploadImage = (e) => {
		e.preventDefault();
		if (files.name !== undefined && distritoUId !== '' && parquesUId !== '' && titulo !== '' && ubi !== '') {
			const storage = app.storage();
			const db = app.firestore();
			const date = new Date();
			const nameFile = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "-" + date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear() + "-" + files.name;
			console.log('start of upload');
			const uploadTask = storage.ref(`/images/${nameFile}`).put(files);

			setLoadingUpload(true);

			//initiates the firebase side uploading
			uploadTask.on(
				'state_changed',
				(snapShot) => {
					//takes a snap shot of the process as it is happening
					console.log(snapShot);
				},
				(err) => {
					//catches the errors
					console.log(err);
				},
				() => {
					// gets the functions from storage refences the image storage in firebase by the children
					// gets the download url then sets the image from firebase as the value for the imgUrl key:
					storage.ref('images').child(`/${nameFile}`).getDownloadURL().then((fireBaseUrl) => {
						db
							.collection('Proyectos')
							.doc('espais-verds')
							.collection('Distritos')
							.doc(distritoUId)
							.collection('Parques')
							.doc(parquesUId)
							.collection('Puertas')
							.add({
								Nombre: titulo,
								Ubicacion: ubi,
								DownloadUrl: fireBaseUrl,
								Fecha: new Date()
							})
							.then(function() {
								setLoadingUpload(false);
								handleResetText();
								handleNext();
								console.log('Document successfully written!');
							})
							.catch(function(error) {
								setLoadingUpload(false);
								console.error('Error writing document: ', error);
							});
					});
				}
			);
		}
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div>
			<Typography variant="h5" component="h6" align="center" className={classes.titleInforme}>
				{found[0]}
			</Typography>
			<Card className={classes.cardInspector}>
				<List>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<AssignmentIndIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={user.email} />
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<ScheduleIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={date} />
					</ListItem>
				</List>
			</Card>
			<ThemeProvider theme={theme}>
				<Card className={classes.cardStepper}>
					<Stepper activeStep={activeStep} orientation="vertical">
						<Step key="Seleccionar Districte">
							<StepLabel>Seleccionar Districte</StepLabel>
							<StepContent>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
										Districte
									</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={distritoUId}
										onChange={(e) => setDistritoUId(e.target.value)}
										labelWidth={labelWidth}
									>
										{distritos.map((distrito) => (
											<MenuItem value={distrito.uid} key={distrito.uid}>
												{distrito.Nombre}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<div className={classes.actionsContainer}>
									<div>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											className={classes.button}
										>
											Enrere
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
										>
											{activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
										</Button>
									</div>
								</div>
							</StepContent>
						</Step>
						<Step key="Seleccionar Parc">
							<StepLabel className={classes.active}>Seleccionar Parc</StepLabel>
							<StepContent>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
										Parc
									</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={parquesUId}
										onChange={(e) => setParquesUId(e.target.value)}
										labelWidth={labelWidth}
									>
										{parques.map((parque) => (
											<MenuItem value={parque.uid} key={parque.uid}>
												{parque.Nombre}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<div className={classes.actionsContainer}>
									<div>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											className={classes.button}
										>
											Enrere
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
										>
											{activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
										</Button>
									</div>
								</div>
							</StepContent>
						</Step>
						<Step key="Nom Porta">
							<StepLabel>Nom de la porta</StepLabel>
							<StepContent>
								<form noValidate autoComplete="off">
									<TextField
										id="outlined-basic"
										label="Nom porta"
										variant="outlined"
										value={titulo}
										onChange={(e) => setTitulo(e.target.value)}
									/>
								</form>
								<div className={classes.actionsContainer}>
									<div>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											className={classes.button}
										>
											Enrere
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
										>
											{activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
										</Button>
									</div>
								</div>
							</StepContent>
						</Step>
						<Step key="Ubicacio Porta">
							<StepLabel>Ubicació de la porta</StepLabel>
							<StepContent>
								<form noValidate autoComplete="off">
									<TextField
										id="outlined-basic"
										label="Ubicació porta"
										variant="outlined"
										value={ubi}
										onChange={(e) => setUbi(e.target.value)}
									/>
								</form>
								<div className={classes.actionsContainer}>
									<div>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											className={classes.button}
										>
											Enrere
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
										>
											{activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
										</Button>
									</div>
								</div>
							</StepContent>
						</Step>
						<Step key="Foto">
							<StepLabel>Foto de la porta</StepLabel>
							<StepContent>
							<FilePond
									allowImageResize={true}
									imageResizeTargetWidth={450}
									imageResizeTargetHeight={450}
									instantUpload={false}
									allowImageCrop={true}
									imageCropAspectRatio={'1:1'}
									imageResizeUpscale={false}
									imageTransformOutputQuality={65}
									onpreparefile={(fileItem, output) => {
										setFiles(output);
									}}
									labelIdle="Pressiona per pujar la fotografia"
								/>
								{loadingUpload === false ? (
									<div className={classes.actionsContainer}>
										<div>
											<Button
												disabled={activeStep === 0}
												onClick={handleBack}
												className={classes.button}
											>
												Enrere
											</Button>
											<Button
												variant="contained"
												color="primary"
												onClick={uploadImage}
												className={classes.button}
											>
												{activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
											</Button>
										</div>
									</div>
								) : (
									<CircularProgress />
								)}
							</StepContent>
						</Step>
					</Stepper>
					{activeStep === steps && (
						<Paper square elevation={0} className={classes.resetContainer}>
							<Typography>Tots els passos completats correctament!</Typography>
							<Button onClick={handleReset} className={classes.button}>
								Tornar
							</Button>
						</Paper>
					)}
				</Card>
			</ThemeProvider>
		</div>
	);
}

export default UploadPorta;
