import React, { useMemo } from 'react';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import app from '../Firebase';
import Spinner from '../Spinner';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { usePosition } from '../Position';
import { useSession } from '../Session';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    display: 'flex',
    marginBottom: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '180px',
  },
  content: {
    flex: '1 0 auto',
    padding: '6px',
  },
  cover: {
    width: 150,
    minHeight: 120,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  titleInforme: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '80%',
    margin: 'auto',
  },
  cardInspector: {
    marginBottom: theme.spacing(2),
    maxWidth: '90%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  cardStepper: {
    maxWidth: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  swithPorta: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  activeCard: {
    display: 'flex',
    marginBottom: 10,
    backgroundColor: '#f1f5b5',
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        '&$active': {
          color: '#BECD00',
        },
        '&$completed': {
          color: '#BECD00',
        },
      },
      text: {
        fill: '#ffffff',
      },
    },
  },
});

/*function useProjects(distritoUID = '', parquesUID = '') {
	const [ projects, setProjects ] = React.useState([]);
	const [ distritos, setDistritos ] = React.useState([]);
	const [ parques, setParques ] = React.useState([]);
	const [ puertas, setPuertas ] = React.useState([]);
	const [ loading, setLoading ] = React.useState(false);
	const [ error, setError ] = React.useState('');

	React.useEffect(
		() => {
			const fetchData = () => {
				setLoading(true);
				const projects = app.firestore().collection('Proyectos').onSnapshot((querySnapshot) => {
					const newProject = querySnapshot.docs.map((doc) => ({
						uid: doc.id,
						...doc.data()
					}));
					setProjects(newProject);
				});
				const distritos = app
					.firestore()
					.collection('Proyectos')
					.doc('espais-verds')
					.collection('Distritos')
					.orderBy('Nombre')
					.onSnapshot((querySnapshot) => {
						const newDistrito = querySnapshot.docs.map((doc) => ({
							uid: doc.id,
							...doc.data()
						}));
						setDistritos(newDistrito);
					});
				if (distritoUID !== '') {
					const parques = app
						.firestore()
						.collection('Proyectos')
						.doc('espais-verds')
						.collection('Distritos')
						.doc(distritoUID)
						.collection('Parques')
						.orderBy('Nombre')
						.onSnapshot((querySnapshot) => {
							const newParque = querySnapshot.docs.map((doc) => ({
								uid: doc.id,
								...doc.data()
							}));
							setParques(newParque);
						});

					if (parquesUID !== '') {
						const puertas = app
							.firestore()
							.collection('Proyectos')
							.doc('espais-verds')
							.collection('Distritos')
							.doc(distritoUID)
							.collection('Parques')
							.doc(parquesUID)
							.collection('Puertas')
							.orderBy('Nombre')
							.onSnapshot((querySnapshot) => {
								const newPuerta = querySnapshot.docs.map((doc) => ({
									uid: doc.id,
									...doc.data()
								}));
								setPuertas(newPuerta);
							});
					}
				}
				console.log('render');
				setLoading(false);
			};
			fetchData();
		},
		[ distritoUID, parquesUID ]
	);

	return { projects, distritos, parques, puertas, loading, error };
}*/

function useProjects() {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      const projects = app
        .firestore()
        .collection('Proyectos')
        .get()
        .then((querySnapshot) => {
          const newProject = querySnapshot.docs.map((doc) => ({
            uid: doc.id,
            ...doc.data(),
          }));
          setProjects(newProject);
        })
        .catch((err) => {
          setError(err);
        });
      console.log('render projects');
      setLoading(false);
    };
    fetchData();
  }, []);
  return { projects, loading, error };
}

function useDistritos() {
  const [distritos, setDistritos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      const distritos = app
        .firestore()
        .collection('Proyectos')
        .doc('espais-verds')
        .collection('Distritos')
        .orderBy('Nombre')
        .get()
        .then((querySnapshot) => {
          const newDistrito = querySnapshot.docs.map((doc) => ({
            uid: doc.id,
            ...doc.data(),
          }));
          setDistritos(newDistrito);
        })
        .catch((err) => {
          setError(err);
        });
      console.log('render distritos');
      setLoading(false);
    };
    fetchData();
  }, []);

  return { distritos, loading, error };
}

function useParques(distritoUID = '') {
  const [parques, setParques] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetchData = () => {
      if (distritoUID !== '') {
        const parques = app
          .firestore()
          .collection('Proyectos')
          .doc('espais-verds')
          .collection('Distritos')
          .doc(distritoUID)
          .collection('Parques')
          .orderBy('Nombre')
          .get()
          .then((querySnapshot) => {
            const newParque = querySnapshot.docs.map((doc) => ({
              uid: doc.id,
              ...doc.data(),
            }));
            setParques(newParque);
          })
          .catch((err) => {
            setError(err);
          });
      }
      console.log('render parques');
    };
    fetchData();
  }, [distritoUID]);
  return { parques, loading, error };
}

function usePuertas(distritoUID = '', parquesUID = '') {
  const [puertas, setPuertas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const fetchData = () => {
      if (distritoUID !== '' && parquesUID !== '') {
        const puertas = app
          .firestore()
          .collection('Proyectos')
          .doc('espais-verds')
          .collection('Distritos')
          .doc(distritoUID)
          .collection('Parques')
          .doc(parquesUID)
          .collection('Puertas')
          .orderBy('Nombre')
          .get()
          .then((querySnapshot) => {
            const newPuerta = querySnapshot.docs.map((doc) => ({
              uid: doc.id,
              ...doc.data(),
            }));
            setPuertas(newPuerta);
          })
          .catch((err) => {
            setError(err);
          });
      }
      console.log('render puertas');
    };
    fetchData();
  }, [distritoUID, parquesUID]);
  return { puertas, loading, error };
}

function AddReport() {
  const classes = useStyles();
  /* Variables Report */
  const [distritoUId, setDistritoUId] = React.useState('');
  const [distritoNombre, setDistritoNombre] = React.useState('');
  const [parquesUId, setParquesUId] = React.useState('');
  const [parquesNombre, setParquesNombre] = React.useState('');
  const [puertaNombre, setPuertaNombre] = React.useState('');
  const [ubicacionPuerta, setUbicacionPuerta] = React.useState('');
  const [tancament, setTancament] = React.useState('');
  const [fotoTancament, setFotoTancament] = React.useState([]);
  const [incidencia, setIncidencia] = React.useState('');
  const [altres, setAltres] = React.useState('');
  const [fotoIncidencia, setFotoIncidencia] = React.useState([]);
  const [tecnic, setTecnic] = React.useState('');
  const settings = useMemo(() => {
    return { enableHighAccuracy: true };
  }, []);
  const { latitude, longitude, error } = usePosition(true, settings);

  /* Variables funcionamiento */
  const [activeStep, setActiveStep] = React.useState(0);
  const [activePorta, setActivePorta] = React.useState();
  const [date, setDate] = React.useState(new Date().toLocaleString());
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const steps = 5;
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [loadingUpload, setLoadingUpload] = React.useState(false);

  /* Preload data */
  const { projects, loading } = useProjects();
  const { distritos } = useDistritos();
  const { parques } = useParques(distritoUId);
  const { puertas } = usePuertas(distritoUId, parquesUId);
  const user = useSession();
  const found = projects.map((project) => project.Nombre);

  console.log(fotoIncidencia.name);
  /* Metodos para variables */
  const handleChangeDistrito = (e) => {
    const uid = e.target.value;
    if (uid !== '') {
      const dist = distritos.find((distrito) => distrito.uid === uid);
      setDistritoNombre(dist.Nombre);
      setParquesUId('');
    }
    setDistritoUId(uid);
  };

  const handleChangeParque = (e) => {
    const uid = e.target.value;
    if (uid !== '') {
      const park = parques.find((parque) => parque.uid === uid);
      console.log(park.Nombre);
      setParquesNombre(park.Nombre);
    }
    setParquesUId(uid);
  };

  const handleClickPuerta = (param) => (e) => {
    e.preventDefault();
    const uid = param;
    if (uid !== '') {
      const puerta = puertas.find((puerta) => puerta.uid === uid);
      console.log(puerta);
      setPuertaNombre(puerta.Nombre);
      setUbicacionPuerta(puerta.Ubicacion);
    }
    setActivePorta(uid);
  };

  /* Metodos para funcionamiento */
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChangeSwith = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    console.log(state);
  };

  const handleResetText = () => {
    setParquesUId('');
    setDistritoUId('');
    setParquesNombre('');
    setDistritoNombre('');
    setFotoIncidencia([]);
    setFotoTancament([]);
    setActivePorta();
    setPuertaNombre('');
    setUbicacionPuerta('');
    setIncidencia('');
    setTancament('');
    setAltres('');
  };

  /* Metodos para Report */
  const handleClickAddReport = (e) => {
    e.preventDefault();
    if (
      distritoUId !== '' &&
      parquesUId !== '' &&
      distritoNombre !== '' &&
      parquesNombre !== '' &&
      puertaNombre !== ''
    ) {
      const storage = app.storage();
      const db = app.firestore();
      const date = new Date();
      let docRef = '';
      let error = false;

      setLoadingUpload(true);

      db.collection('Informes')
        .add({
          Parque: parquesNombre,
          Distrito: distritoNombre,
          Puerta: puertaNombre,
          Ubicacion: ubicacionPuerta,
          Tancament: state.checkedA === true ? 'SI' : 'NO',
          Date: date.toLocaleString('es-ES'),
          Latitude: latitude !== undefined ? latitude : '',
          Longitude: longitude !== undefined ? longitude : '',
          IndicenciaTancament: tancament === 'Altres' ? altres : tancament,
          FotoTancament: '',
          Indicencia: incidencia,
          FotoIncidencia: '',
          Tecnico: user.email,
        })
        .then(function (doc) {
          docRef = doc.id;
          console.log('Document successfully written!');
        })
        .catch(function (error) {
          setLoadingUpload(false);
          error = true;
          console.error('Error writing document: ', error);
        });

      if (!state.checkedA) {
        if (fotoTancament.name !== undefined) {
          const nameTancamentFile =
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds() +
            '-' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            '-' +
            'tacament' +
            '-' +
            fotoTancament.name;

          console.log('start of upload tancament foto');
          const uploadTancamentTask = storage
            .ref(`/images/${nameTancamentFile}`)
            .put(fotoTancament);

          //initiates the firebase side uploading
          uploadTancamentTask.on(
            'state_changed',
            (snapShot) => {
              //takes a snap shot of the process as it is happening
              console.log(snapShot);
            },
            (err) => {
              //catches the errors
              setLoadingUpload(false);
              error = true;
              console.log(err);
            },
            () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref('images')
                .child(`/${nameTancamentFile}`)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  console.log(fireBaseUrl);
                  db.collection('Informes')
                    .doc(docRef)
                    .update({
                      FotoTancament: fireBaseUrl,
                    })
                    .then(function () {
                      console.log('Foto tancament subida!');
                    })
                    .catch(function (error) {
                      error = true;
                      console.error('Error writing document: ', error);
                    });
                })
                .catch((err) => {
                  error = true;
                  setLoadingUpload(false);
                });
            }
          );
        }
      }

      if (fotoIncidencia.name !== undefined) {
        console.log('entra en incidencia');
        const nameIncidenciaFile =
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds() +
          '-' +
          date.getDate() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getFullYear() +
          '-' +
          'incidencia' +
          '-' +
          fotoIncidencia.name;

        console.log('start of upload tancament foto');
        const uploadTancamentTask = storage
          .ref(`/images/${nameIncidenciaFile}`)
          .put(fotoIncidencia);

        //initiates the firebase side uploading
        uploadTancamentTask.on(
          'state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot);
          },
          (err) => {
            //catches the errors
            setLoadingUpload(false);
            error = true;
            console.log(err);
          },
          () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            storage
              .ref('images')
              .child(`/${nameIncidenciaFile}`)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                console.log(fireBaseUrl);
                db.collection('Informes')
                  .doc(docRef)
                  .update({
                    FotoIncidencia: fireBaseUrl,
                  })
                  .then(function () {
                    console.log('Foto incidencia subida!');
                  })
                  .catch(function (error) {
                    error = true;
                    console.error('Error writing document: ', error);
                  });
              })
              .catch((err) => {
                error = true;
                setLoadingUpload(false);
              });
          }
        );
      }

      if (!error) {
        setLoadingUpload(false);
        handleResetText();
        handleNext();
        error = false;
        docRef = '';
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Typography
        variant="h5"
        component="h6"
        align="center"
        className={classes.titleInforme}
      >
        {found[0]}
      </Typography>
      <Card className={classes.cardInspector}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AssignmentIndIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.email} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ScheduleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={date} />
          </ListItem>
        </List>
      </Card>
      <ThemeProvider theme={theme}>
        <Card className={classes.cardStepper}>
          <Stepper
            style={{ padding: '10px' }}
            activeStep={activeStep}
            orientation="vertical"
          >
            <Step key="Seleccionar Districte">
              <StepLabel>Seleccionar Districte</StepLabel>
              <StepContent>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={inputLabel}
                    id="demo-simple-select-outlined-label"
                  >
                    Districte
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={distritoUId}
                    onChange={handleChangeDistrito}
                    labelWidth={labelWidth}
                  >
                    {distritos.map((distrito) => (
                      <MenuItem value={distrito.uid} key={distrito.uid}>
                        {distrito.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Enrere
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="Seleccionar Parc">
              <StepLabel className={classes.active}>Seleccionar Parc</StepLabel>
              <StepContent>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={inputLabel}
                    id="demo-simple-select-outlined-label"
                  >
                    Parc
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={parquesUId}
                    onChange={handleChangeParque}
                    labelWidth={labelWidth}
                  >
                    {parques.map((parque) => (
                      <MenuItem value={parque.uid} key={parque.uid}>
                        {parque.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Enrere
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="Seleccionar Porta">
              <StepLabel>Seleccionar Porta</StepLabel>
              <StepContent>
                {puertas.map((puerta) => (
                  <Card
                    className={
                      puerta.uid === activePorta
                        ? classes.activeCard
                        : classes.card
                    }
                    key={puerta.uid}
                    onClick={handleClickPuerta(puerta.uid)}
                  >
                    <CardMedia
                      className={classes.cover}
                      image={puerta.DownloadUrl}
                      title={puerta.Nombre}
                    />
                    <div className={classes.details}>
                      <CardContent
                        style={{ paddingBottom: '4px' }}
                        className={classes.content}
                      >
                        <Typography variant="subtitle1">
                          {puerta.Nombre}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {puerta.Ubicacion}
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                ))}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Enrere
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="Porta tancada">
              <StepLabel>Estat de la porta</StepLabel>
              <StepContent>
                <FormGroup row className={classes.swithPorta}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.checkedA}
                        onChange={handleChangeSwith('checkedA')}
                        value="checkedA"
                      />
                    }
                    label="Porta tancada?"
                  />
                </FormGroup>
                {!state.checkedA && (
                  <FilePond
                    allowImageResize={true}
                    imageResizeTargetWidth={450}
                    imageResizeTargetHeight={450}
                    instantUpload={false}
                    allowImageCrop={true}
                    imageCropAspectRatio={'1:1'}
                    imageResizeUpscale={false}
                    imageTransformOutputQuality={65}
                    onpreparefile={(fileItem, output) => {
                      setFotoTancament(output);
                    }}
                    labelIdle="Pressiona per pujar la fotografia"
                  />
                )}
                {!state.checkedA && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel
                      ref={inputLabel}
                      id="demo-simple-select-outlined-label"
                    >
                      No tanca
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={tancament}
                      onChange={(e) => setTancament(e.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'Material dins del pany'}>
                        Material dins del pany
                      </MenuItem>
                      <MenuItem value={'Estructura del pany danyada'}>
                        Estructura del pany danyada
                      </MenuItem>
                      <MenuItem value={'Cadena que no és dPIJ'}>
                        Cadena que no és d'PIJ
                      </MenuItem>
                      <MenuItem
                        value={'Personal que no vol desallotjar el parc'}
                      >
                        Personal que no vol desallotjar el parc
                      </MenuItem>
                      <MenuItem value={'Altres'}>Altres</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {tancament === 'Altres' && (
                  <form noValidate autoComplete="off">
                    <TextField
                      id="outlined-basic"
                      label="Observacions"
                      variant="outlined"
                      value={altres}
                      onChange={(e) => setAltres(e.target.value)}
                    />
                  </form>
                )}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Enrere
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="Incidencies">
              <StepLabel>Incidències al parc</StepLabel>
              <StepContent>
                <FilePond
                  allowImageResize={true}
                  imageResizeTargetWidth={450}
                  imageResizeTargetHeight={450}
                  instantUpload={false}
                  allowImageCrop={true}
                  imageCropAspectRatio={'1:1'}
                  imageResizeUpscale={false}
                  imageTransformOutputQuality={65}
                  onpreparefile={(fileItem, output) => {
                    setFotoIncidencia(output);
                  }}
                  labelIdle="Pressiona per pujar la fotografia"
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={inputLabel}
                    id="demo-simple-select-outlined-label"
                  >
                    Incidències
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={incidencia}
                    onChange={(e) => setIncidencia(e.target.value)}
                    labelWidth={labelWidth}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Fuga d’aigua d’una font'}>
                      Fuga d’aigua d’una font
                    </MenuItem>
                    <MenuItem value={'Fuga d’aigua de riego'}>
                      Fuga d’aigua de riego
                    </MenuItem>
                    <MenuItem value={'Inundació per pluges'}>
                      Inundació per pluges
                    </MenuItem>
                    <MenuItem value={'Arbres caïdes'}>Arbres caïdes</MenuItem>
                    <MenuItem value={'Actes vandàlics'}>
                      Actes vandàlics
                    </MenuItem>
                    <MenuItem value={'La porta ja estava tancada'}>
                      La porta ja estava tancada
                    </MenuItem>
                    <MenuItem value={'Porta tancada per altre cadenat'}>
                      Porta tancada per altre cadenat
                    </MenuItem>
                  </Select>
                </FormControl>
                {loadingUpload === false ? (
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Enrere
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickAddReport}
                        className={classes.button}
                      >
                        {activeStep === steps - 1 ? 'Acabar' : 'Pròxim'}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <CircularProgress />
                )}
              </StepContent>
            </Step>
          </Stepper>
          {activeStep === steps && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>Tots els passos completats!</Typography>
              <Button onClick={handleReset} className={classes.button}>
                Tornar
              </Button>
            </Paper>
          )}
        </Card>
      </ThemeProvider>
    </div>
  );
}

export default AddReport;
