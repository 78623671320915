import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSession } from '../Session';

import * as ROUTES from '../../constants/Routes';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
	const user = useSession();

	return (
	  <Route
		{...rest}
		render={routeProps =>
		  !!user ? (
			<RouteComponent {...routeProps} />
		  ) : (
			<Redirect to={ROUTES.SIGN_IN} />
		  )
		}
	  />
	);
  };

export default PrivateRoute;