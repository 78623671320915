import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { userContext } from './context/UserContext';
import app from './components/Firebase';
import Spinner from './components/Spinner';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import * as ROUTES from './constants/Routes';

const App = () => {
	const [ user, initialising ] = useAuthState(app.auth());

	if (initialising) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}

	return (
		<userContext.Provider
			value={{
				user: user,
				initialising
			}}
		>
			<BrowserRouter>
				<Switch>
					<Route exact path={ROUTES.SIGN_IN} component={SignIn} />
					<PrivateRoute component={Dashboard} />
				</Switch>
			</BrowserRouter>
		</userContext.Provider>
	);
};

export default App;
